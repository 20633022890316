import Main from '../Main';
import ContainerLayout from '../ContainerLayout';
import {
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import React from 'react';
import suspendedAccountMessages from '../../i18n/suspendedAccount.messages';
import { useIntl } from 'react-intl';
import { PostAuthComponentParams } from '../../types/auth';

const SuspendedAccount = ({
  config,
  onContinue,
  onCancel,
  user,
}: PostAuthComponentParams) => {
  const { formatMessage } = useIntl();
  if (config.enableSuspendedAccountFlow !== 'true') {
    onContinue();
    return null;
  }
  if (user?.suspended !== 'true') {
    onContinue();
    return null;
  }
  return (
    <>
      <Main config={config}>
        <ContainerLayout>
          <Container
            header={
              <Header variant={'h2'}>
                {formatMessage(suspendedAccountMessages.header)}
              </Header>
            }
          >
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      onCancel();
                    }}
                    data-testid={'return-to-signin'}
                    variant={'primary'}
                  >
                    {formatMessage(
                      suspendedAccountMessages.returnToSignInPageButton
                    )}
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween direction="vertical" size="xs">
                <TextContent>
                  {formatMessage(suspendedAccountMessages.messageBody)}
                </TextContent>
              </SpaceBetween>
            </Form>
          </Container>
        </ContainerLayout>
      </Main>
    </>
  );
};
export default SuspendedAccount;
